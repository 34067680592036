import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"

// components
import CaseItem from "components/CaseItem"

// styles
import styles from "./caseoverview.module.scss"

class CaseOverview extends Component {
  componentDidMount() {
    const tl = anime.timeline({
      easing: "easeOutCubic",
      duration: 1500,
    })

    tl.add(
      {
        targets: this.refs.intro.querySelectorAll(
          `.${styles.title} span > span`
        ),
        translateY: ["172px", "0px"],
        duration: 1200,
        easing: "easeInOutExpo",
        delay: anime.stagger(100),
      },
      "-=500"
    )
      .add(
        {
          targets: this.refs.intro.querySelector(`.${styles.line}`),
          duration: 400,
          width: ["0%", "100%"],
          easing: "easeInOutCirc(1, .5)",
        },
        "-=800"
      )
      .add(
        {
          targets: this.refs.intro.querySelectorAll(
            `.${styles.subtitle} span:first-child`
          ),
          translateY: ["104px", "0px"],
          duration: 800,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        "-=600"
      )
      .add(
        {
          targets: this.refs.intro.querySelector(`.${styles.intro}`),
          translateY: ["24px", "0px"],
          opacity: [0, 1],
          duration: 400,
          easing: "easeInOutExpo",
        },
        "-=200"
      )
  }
  render() {
    const cases = this.props.cases
    const casePrefix = this.props.prefix
    return (
      <>
        <div className={styles.container} ref="intro">
          <span className={styles.subtitle}>
            <span>ONS WERK</span>
            <span className={styles.line} />
          </span>
          <p className={styles.intro}>
            In deze cases laten we je zien hoe wij onze klanten laten groeien door te verleiden, verleuken en versimpelen.
          </p>
        </div>
        <div className={styles.cases}>
          {cases.map((item, i) => (
            <CaseItem key={i} case={item.node} casePrefix={casePrefix} />
          ))}
        </div>
      </>
    )
  }
}

export default CaseOverview
